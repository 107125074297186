import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@periodica/ui-kit';
import { filtersType } from '../prop-types/filters';
import styles from './Filters.module.scss';

// TODO: may be never used
function Filters({ filters, activeId, handler }) {
  const handleSelectFilter = (value) => {
    handler(value);
  };
  return (
    <div className={styles.filters}>
      {filters.map((filter) => (
        <Button
          key={filter.id}
          onClick={() => handleSelectFilter(filter.id)}
          className={styles.filtersItem}
          size="medium"
          variant={filter.id !== activeId ? 'secondary' : 'brandPrimary'}
        >
          {filter.title}
        </Button>
      ))}
    </div>
  );
}

Filters.propTypes = {
  filters: propTypes.arrayOf(filtersType).isRequired,
  activeId: propTypes.string,
  handler: propTypes.func.isRequired,
};

Filters.defaultProps = {
  activeId: '',
};

export default Filters;
