import React, { useState, useEffect, useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { productType } from '../prop-types/product';
import { filtersType } from '../prop-types/filters';
import { Header } from './Header';
import Filters from './Filters';
import { Products } from './Products';
import styles from './ProductsBlock.module.scss';

// TODO: may be never used with filters ??
function ProductsBlock({ products, filters, header }) {
  const [groupId, setGroup] = useState(filters[0]?.id);
  const haveFilters = useMemo(() => !!filters?.length, [filters]);
  const currentGroup = haveFilters ? filters.find((f) => f.id === groupId).products : products;
  const handleSetFilter = useCallback(
    (id) => () => {
      setGroup(id);
    },
    [setGroup]
  );
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const hashUrl = location.hash;
    if (hashUrl) {
      setGroup(hashUrl.replace('#', ''));
    }
  }, []);
  return (
    <>
      {header && (
        <div
          className={cn({
            [styles.header]: true,
            [styles.noFiltersHeader]: !filters.length,
          })}
        >
          <Header littleHeader>{header}</Header>
        </div>
      )}
      {filters.length ? (
        <Filters filters={filters} handler={handleSetFilter} activeId={groupId} />
      ) : null}
      <Products products={currentGroup} />
    </>
  );
}

ProductsBlock.propTypes = {
  products: propTypes.arrayOf(productType).isRequired,
  filters: propTypes.arrayOf(filtersType),
  header: propTypes.string,
};

ProductsBlock.defaultProps = {
  filters: [],
  header: '',
};

export default ProductsBlock;
